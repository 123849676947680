import "./Home.css";
import "../App.css";

import { useEffect, useState } from "react";

import Rankings from "./Rankings";
import { db } from "../firebase";
import vla from "../vla.webp";

function Home() {
  /* DATA */

  const [teams, setTeams] = useState([]);
  const [games, setGames] = useState([]);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [loadingMatches, setLoadingMatches] = useState(true);

  const cutoff = new Date("2024-12-01");

  useEffect(() => {
    getTeamData();
    console.log("Doing things...");
    db.collection("data/vladashboard/confirmed")
      .where("date", ">", cutoff)
      .get()
      .then((querySnapshot) => {
        console.log("Reading docs...");
        let innerArr = [];
        querySnapshot.docs.forEach((doc) => {
          if (!doc.data().ignored) {
            innerArr.push(doc.data());
          }
        });
        setLoadingMatches(false);
        setGames(innerArr);
      })
      .catch((error) => {
        setLoadingMatches(false);
        console.log("Games.js Error - No games for team found");
      });
  }, []);

  function preloadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        console.log(img);
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
    });
  }

  const loadAllImgs = (innerArr) => {
    let isCancelled = false;
    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList = [];
      for (const i of innerArr) {
        if (i.vlaurl) {
          imagesPromiseList.push(preloadImage(i.vlaurl));
        }
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }
      setTeams(innerArr);
    }

    effect();
  };

  const getTeamData = () => {
    db.collection("teams")
      .where("vla2023", "==", true)
      .get()
      .then((querySnapshot) => {
        let innerArr = [];
        querySnapshot.docs.forEach((doc) => {
          let tmpteam = doc.data();
          tmpteam.id = doc.id;
          innerArr.push(tmpteam);
        });
        setLoadingTeams(false);
        loadAllImgs(innerArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* UI */

  const [overall, setOverall] = useState(true);
  const [tier, setTier] = useState(1);

  return (
    <div className="App">
      <div className="selectType">
        <a onClick={() => setTier(1)}>
          <h1 style={tier == 1 ? {} : { color: "lightgray" }}>Tier 1</h1>
        </a>
        <a onClick={() => setTier(2)}>
          <h1 style={tier == 2 ? {} : { color: "lightgray" }}>Tier 2</h1>
        </a>
        <a onClick={() => setTier(3)}>
          <h1 style={tier == 3 ? {} : { color: "lightgray" }}>Tier 3</h1>
        </a>
        <a onClick={() => setTier(101)}>
          <h1 style={tier == 101 ? {} : { color: "lightgray" }}>Women</h1>
        </a>
      </div>
      <div className="selectType">
        <a onClick={() => setOverall(true)}>
          <h5 style={overall ? {} : { color: "lightgray" }}>
            Overall Standings
          </h5>
        </a>
        <a onClick={() => setOverall(false)}>
          <h5 style={overall ? { color: "lightgray" } : {}}>
            Division Standings
          </h5>
        </a>
      </div>
      {loadingMatches || loadingTeams ? (
        <div>...</div>
      ) : overall ? (
        <Rankings teams={teams} games={games} tier={tier} />
      ) : (
        <>
          <Rankings teams={teams} games={games} tier={tier} division={1} />
          <Rankings teams={teams} games={games} tier={tier} division={2} />
          <Rankings teams={teams} games={games} tier={tier} division={3} />
          <Rankings teams={teams} games={games} tier={tier} division={4} />
        </>
      )}
    </div>
  );
}

export default Home;
