const Row = ({ team, index, tier }) => {
	const split = team.name.replace(" W ", " ").split(" ");
	return (
		<tr key={team.joinCode}>
			<td>{index + 1}</td>
			{/* <td>{divisions[team.division]}</td> */}
			{tier == 1 && team.vlaurl ? (
				<td className="img">
					<img src={team.vlaurl} />
				</td>
			) : null}
			<td
				className="nametd"
				style={
					tier > 1
						? {
								background: "#" + team.color,
								display: "flex",
								alignItems: "center",
						  }
						: { background: "#" + team.color }
				}
			>
				{tier > 1 ? (
					<h3 style={{ marginRight: "8px" }} className="emoji">
						{split[split.length - 1]}
					</h3>
				) : null}
				<div>
					<h6>{team.location}</h6>
					<h5>{split.slice(0, split.length - 1).join(" ")}</h5>
				</div>
			</td>

			<td className="white">{team.matchesWon}</td>
			<td className="white">{team.matchesLost}</td>
			<td className="white tiny">
				{parseInt(team.points ?? 0) +
					parseInt(team.exPoints ?? 0) +
					parseInt(team.tier2tournament ?? 0)}
			</td>
			{tier == 1 ? (
				<td>
					{team.matchesWon + team.matchesLost > 0
						? (
								(parseInt(team.points ?? 0) + parseInt(team.exPoints ?? 0)) /
								(team.matchesWon + team.matchesLost)
						  ).toFixed(2)
						: "-"}
				</td>
			) : (
				<td>
					{parseInt(team.points ?? 0) +
						parseInt(team.exPoints ?? 0) +
						parseInt(team.carryover ?? 0) +
						parseInt(team.tier2tournament ?? 0)}
				</td>
			)}
			<td>{team.cupWins > 0 ? team.cupWins : "-"}</td>
		</tr>
	);
};

const Rankings = ({ teams, games, tier, division }) => {
	if (teams.length == 0) {
		return null;
	}
	for (let i = 0; i < teams.length; i++) {
		teams[i].points = 0;
		teams[i].exPoints = 0;
		teams[i].matchesWon = 0;
		teams[i].matchesLost = 0;
		teams[i].setsWon = 0;
		teams[i].setsLost = 0;
		teams[i].pointsWon = 0;
		teams[i].pointsLost = 0;
	}
	let dic = Object.assign({}, ...teams.map((x) => ({ [x.id]: x })));
	for (let game of games) {
		if (
			game.opponentID == "7FLjh1rRrdyhujvvECX6" ||
			game.team == "7FLjh1rRrdyhujvvECX6"
		) {
			console.log(`${game.opponentID} vs ${game.teamName}`);
		}
		if (tier > 1 || !game.exhibition) {
			// COUNTS # POINTS WON
			for (let i = 0; i < 5; i++) {
				dic[game.team].pointsWon += game.ourScores[i];
				dic[game.opponentID].pointsWon += game.theirScores[i];
				dic[game.team].pointsLost += game.theirScores[i];
				dic[game.opponentID].pointsLost += game.ourScores[i];
			}
			// COUNT # SETS WON
			dic[game.team].setsWon += game.ourSets;
			dic[game.team].setsLost += game.theirSets;
			dic[game.opponentID].setsWon += game.theirSets;
			dic[game.opponentID].setsLost += game.ourSets;

			// COUNT # MATCHES WON
			if (game.ourSets == 3) {
				dic[game.team].matchesWon += 1;
				dic[game.opponentID].matchesLost += 1;
			} else if (game.theirSets == 3) {
				dic[game.team].matchesLost += 1;
				dic[game.opponentID].matchesWon += 1;
			}
		}
		// CALCULATE POINTS
		if (game.exhibition) {
			if (dic[game.team].tier > 1 && game.ourSets == 3) {
				dic[game.team].exPoints += 1;
			} else if (dic[game.opponentID].tier > 1 && game.theirSets == 3) {
				dic[game.opponentID].exPoints += 1;
			}
		} else {
			let mod = 0;
			let opMod = 0;
			if (dic[game.team].tier > 1 && dic[game.opponentID].tier == 1) {
				// WE ARE TIER 2 and THEY ARE 1
				mod = 1;
			} else if (dic[game.team].tier == 1 && dic[game.opponentID].tier > 1) {
				// WE ARE TIER 1 and THEY ARE 2
				opMod = 1;
			}
			if (game.ourSets == 3) {
				if (game.theirSets == 2) {
					// OUR TEAM 3-2 WIN
					dic[game.team].points += 2 + mod; //We get 2 points (or 3 if we're tier 2 vs they're tier 1)
					dic[game.opponentID].points += 1 + opMod; //They get 1 point (or 2 if they're tier 2 vs we're tier 1)
				} else {
					// OUR TEAM 3-0 or 3-1
					dic[game.team].points += 3 + mod; //We get 3 points (or 4 if we're tier 2 vs they're tier 1)
				}
			} else if (game.theirSets == 3) {
				if (game.ourSets == 2) {
					// THEIR TEAM 3-2 WIN
					dic[game.opponentID].points += 2 + opMod; //They get 2 points (or 1 if they're tier 2 vs we're tier 1)
					dic[game.team].points += 1 + mod; //We get 1 points (or 2 if we're tier 2 vs they're tier 1)
				} else {
					// THEIR TEAM 3-0 or 3-1
					dic[game.opponentID].points += 3 + opMod; //They get 3 points (or 4 if they're tier 2 vs we're tier 1)
				}
			}
		}
		if (
			game.opponentID == "7FLjh1rRrdyhujvvECX6" ||
			game.team == "7FLjh1rRrdyhujvvECX6"
		) {
			// console.log(dic["7FLjh1rRrdyhujvvECX6"]);
			console.log(dic["7FLjh1rRrdyhujvvECX6"].exPoints);
		}
	}
	const divisions = ["", "Central", "East", "West", "North"];
	return (
		<>
			{division ? <h4>{divisions[division]}</h4> : <></>}
			<table>
				<thead>
					<tr>
						<td className="none">{/*rank*/}</td>
						{/* <td className="none">logo</td> */}
						{tier == 1 ? <td className="none emoji">{/*logo*/}</td> : null}
						<td className="none">{/*name*/}</td>
						<td className="white">Won</td>
						<td className="white">Lost</td>
						<td className="white tiny">
							<span className="small">'25</span>
							<span className="large">2025</span> PTS
						</td>
						{tier == 1 ? <td> PTS / MP</td> : <td>TOT</td>}

						<td className="cup">CUP WINS</td>
					</tr>
				</thead>
				<tbody>
					{teams
						.filter((team) =>
							division
								? team.division == division && team.tier == tier
								: team.tier == tier
						)
						.sort((a, b) => {
							const aPPM =
								a.matchesWon + a.matchesLost > 0
									? (parseInt(a.points ?? 0) + parseInt(a.exPoints ?? 0)) /
									  (a.matchesWon + a.matchesLost)
									: -1;
							const bPPM =
								b.matchesWon + b.matchesLost > 0
									? (parseInt(b.points ?? 0) + parseInt(b.exPoints ?? 0)) /
									  (b.matchesWon + b.matchesLost)
									: -1;
							const aMR = a.matchesWon / (a.matchesLost + 1);
							const bMR = b.matchesWon / (b.matchesLost + 1);
							const compare =
								tier == 1
									? parseInt(b.points ?? 0) +
									  parseInt(b.exPoints ?? 0) +
									  parseInt(b.tier2tournament ?? 0) +
									  parseInt(division ? 0 : parseInt(b.carryover ?? 0)) -
									  (parseInt(a.points ?? 0) +
											parseInt(a.exPoints ?? 0) +
											parseInt(a.tier2tournament ?? 0) +
											parseInt(division ? 0 : parseInt(a.carryover ?? 0)))
									: parseInt(b.points ?? 0) +
									  parseInt(b.exPoints ?? 0) +
									  parseInt(b.tier2tournament ?? 0) +
									  parseInt(division ? parseInt(b.carryover ?? 0) : 0) -
									  (parseInt(a.points ?? 0) +
											parseInt(a.exPoints ?? 0) +
											parseInt(a.tier2tournament ?? 0) +
											parseInt(division ? parseInt(a.carryover ?? 0) : 0));
							return compare == 0 ? bMR - aMR : compare;
						})
						.sort((a, b) =>
							tier == 1
								? (parseInt(b.points ?? 0) + parseInt(b.exPoints ?? 0)) /
										(b.matchesWon + b.matchesLost) -
								  (parseInt(a.points ?? 0) + parseInt(a.exPoints ?? 0)) /
										(a.matchesWon + a.matchesLost)
								: 0
						)
						.sort((a, b) =>
							division
								? true
								: parseInt(b.cupWins ?? 0) - parseInt(a.cupWins ?? 0)
						)
						.map((team, index) => {
							return (
								<Row team={team} index={index} key={team.id} tier={tier} />
							);
						})}
				</tbody>
			</table>
		</>
	);
};

export default Rankings;
